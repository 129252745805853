import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import HelpRow from './HelpRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import Pagination from '@mui/material/Pagination';

import { getAllVideo, saveVideo } from '../../services/VideoService';

export default function VideoTable() {
    const [videos, setVideos] = useState([]);
    const [notification, setNotification] = useState({ type: '', text: '' });
    const [count, setCount] = useState(0); // Total de vídeos para calcular o número de páginas
    const [page, setPage] = useState(1); // Página atual
    const [title, setTitle] = useState(''); // Termo de pesquisa
    const [description, setDescription] = useState('');
    const [videoFile, setVideoFile] = useState(null);

    // Função para buscar os vídeos com base na página atual
    const fetchVideos = async (page = 1) => {
        try {
            const response = await getAllVideo(page); // Passa apenas a página
            setVideos(response.rows); // Vídeos retornados
            setCount(response.total); // Total de vídeos para calcular a paginação
        } catch (error) {
            console.error('Erro ao buscar vídeos', error);
            setNotification({ type: 'error', text: 'Erro ao carregar vídeos' });
        }
    };

    useEffect(() => {
        fetchVideos(page); // Carrega os vídeos ao montar o componente ou alterar a página
    }, [page]);

    const handlePageChange = (_, value) => {
        setPage(value); // Atualiza a página atual
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!title || !description || !videoFile) {
            setNotification({ type: 'error', text: 'Todos os campos são obrigatórios!' });
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('videoUrl', videoFile);

        try {
            const response = await saveVideo(formData);
            setNotification({ type: 'success', text: 'Vídeo enviado com sucesso!' });
            fetchVideos(page); // Recarrega os vídeos após o envio
        } catch (error) {
            setNotification({ type: 'error', text: 'Erro ao enviar o vídeo!' });
        }
    };

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Cadstro Support</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card card-body border-0 shadow table-wrapper table-responsive">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Título do Vídeo</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Digite o título do vídeo"
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Descrição do Vídeo</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Digite a descrição do vídeo"
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="video" className="form-label">Arquivo de Vídeo</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="video"
                                    onChange={(e) => setVideoFile(e.target.files[0])}
                                    accept="video/*"
                                />
                            </div>

                            <button type="submit" className="btn btn-primary">Enviar Vídeo</button>
                        </form>
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="card card-body border-0 shadow table-wrapper table-responsive">
                        <TableContainer component={Paper}>
                            <Table className="table table-hover" aria-label="collapsible table">
                                <TableHead>
                                    <TableRow className="d-block mb-4 mb-md-0">
                                        <TableCell className="h4">Suporte User</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="border-gray-200">
                                    {videos.map((video) => (
                                        <HelpRow key={video.id} row={video} />
                                    ))}
                                </TableBody>
                            </Table>
                            <br></br>
                            <Pagination
                                count={Math.ceil(count / 8)} // Número total de páginas
                                page={page}
                                onChange={handlePageChange} // Lida com a mudança de página
                            />
                            <br></br>
                        </TableContainer>
                   
                    </div>
                </div>

                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
        </>
    );
}
