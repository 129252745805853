import React, { useState } from 'react';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import { sendInfoAll } from '../../services/UsersService';

function Message() {
    const [message, setMessage] = useState('');
    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [notification, setNotification] = useState({ type: '', text: '' });

    function handleMessageChange(event) {
        setMessage(event.target.value);
    }

    function handleImageChange(event) {
        const file = event.target.files[0];
        if (file) {
            setImage(file); // Save the selected image file
        }
    }

    function handleVideoChange(event) {
        const file = event.target.files[0];
        if (file) {
            setVideo(file); // Save the selected video file
        }
    }

    function handleSendMessage() {
        if (message.trim() || image || video) {
            // Prepare the form data
            const formData = new FormData();
            formData.append('message', message);
            if (image) formData.append('image', image);
            if (video) formData.append('video', video);
    
            // Log FormData contents to check what is being appended
            for (let [key, value] of formData.entries()) {
                console.log(key, value);  // Log the keys and values of FormData
            }
    
            sendInfoAll(formData)
                .then(() => {
                    setNotification({ type: 'success', text: 'Message sent to all users successfully!' });
                    setMessage(''); // Clear the message field after sending
                    setImage(null);  // Clear the selected image
                    setVideo(null);  // Clear the selected video
                })
                .catch(err => {
                    setNotification({ type: 'error', text: err.message });
                });
        } else {
            setNotification({ type: 'error', text: 'Please enter a message or select a file to send.' });
        }
    }
    

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Send Message</h2>
                    </div>
                </div>

                {/* Form to send message */}
                <div className="card card-body border-0 shadow mb-4">
                    <h4 className="mb-3">Send a Message to All Users</h4>
                    <textarea
                        className="form-control"
                        rows="4"
                        value={message}
                        onChange={handleMessageChange}
                        placeholder="Type your message here"
                    />
                    
                    <div className="mt-3">
                        <label className="form-label">Select Image</label>
                        <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                    </div>

                    <div className="mt-3">
                        <label className="form-label">Select Video</label>
                        <input
                            type="file"
                            className="form-control"
                            accept="video/*"
                            onChange={handleVideoChange}
                        />
                    </div>

                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSendMessage}
                    >
                        Send Message
                    </button>
                </div>
                <Footer />
            </main>

            <Toast text={notification.text} type={notification.type} />
        </>
    );
}

export default Message;
