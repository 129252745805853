import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import HelpRow from './GraphicAnalysisRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import Pagination from '@mui/material/Pagination';

import { getAllGraphicAnalysisService, saveGraphicAnalysisService, deleteGraphicAnalysisService } from '../../services/GraphicAnalysisService';

export default function GraphicAnalysis() {
    const [images, setImage] = useState([]);
    const [notification, setNotification] = useState({ type: '', text: '' });
    const [count, setCount] = useState(0); // Total de vídeos para calcular o número de páginas
    const [page, setPage] = useState(1); // Página atual
    const [title, setTitle] = useState(''); // Termo de pesquisa
    const [symbol, setSymbol] = useState('');
    const [feeling, setFeeling] = useState('');
    const [standardStage, setStandardStage] = useState('');
    const [graphicPattern, setGraphicPattern] = useState('');
    const [tradeSetup, setTradeSetup] = useState('');
    const [graphicPatternDescription, setGraphicPattern_Description] = useState('');
    const [trend, setTrend] = useState('');
    const [momentum, setMomentum] = useState('');
    const [supportAndResistance, setSupportAndResistance] = useState('');
    const [graphicFile, setGraphicFile] = useState(null);
    const [deleting, setDeleting] = useState(false);

    // Função para buscar os vídeos com base na página atual
    const fetchImage = async (page = 1) => {
        try {
            const response = await getAllGraphicAnalysisService(page); // Passa apenas a página
            setImage(response.rows || []); // Certifique-se de que rows é um array
            setCount(response.total || 0); // Garante um valor padrão
        } catch (error) {
            console.error('Erro ao buscar imagens:', error);
            setImage([]); // Evita estado indefinido em caso de erro
            setNotification({ type: 'error', text: 'Erro ao carregar imagens' });
        }
    };

    useEffect(() => {
        fetchImage(page); // Carrega os vídeos ao montar o componente ou alterar a página
    }, [page]);

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchImage(value); // Atualiza os dados da página
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!title || !symbol || !feeling || !feeling || !standardStage || !graphicPattern || !tradeSetup || !graphicPatternDescription || !trend || !momentum || !supportAndResistance || !graphicFile) {
            setNotification({ type: 'error', text: 'Todos os campos são obrigatórios!' });
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('symbol', symbol);
        formData.append('feeling', feeling);
        formData.append('standardStage', standardStage);
        formData.append('graphicPattern', graphicPattern);
        formData.append('tradeSetup', tradeSetup);
        formData.append('graphicPattern_description', graphicPatternDescription);
        formData.append('trend', trend);
        formData.append('momentum', momentum);
        formData.append('support_and_Resistance', supportAndResistance);
        formData.append('imageUrl', graphicFile);

        try {
            const response = await saveGraphicAnalysisService(formData);
            setNotification({ type: 'success', text: 'Graphic Analysis sent successfully!!' });
            fetchImage(page); // Recarrega os vídeos após o envio
        } catch (error) {
            setNotification({ type: 'error', text: 'Error when sending Graphic Analysis!' });
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this graphic analysis?')) return;
        setDeleting(true);
        try {
            await deleteGraphicAnalysisService(id);
            setImage(); // Atualiza a lista de imagens após a exclusão
        } catch (error) {
            console.error('Error deleting graphic analysis:', error);
            alert('Failed to delete the graphic analysis.');
        } finally {
            setDeleting(false);
        }
    };

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Graphic Analysis Register</h1>
                    </div>
                </div>


                <div className="row">
                    <div className="card card-body border-0 shadow table-wrapper table-responsive">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Enter the title of the Graphic Analysis"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="symbol" className="form-label">Symbol</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="symbol"
                                    value={symbol}
                                    onChange={(e) => setSymbol(e.target.value)}
                                    placeholder="Enter the symbol of the Graphic Analysis"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="feeling" className="form-label">Feeling</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="feeling"
                                    value={feeling}
                                    onChange={(e) => setFeeling(e.target.value)}
                                    placeholder="Enter the feeling of the Graphic Analysis"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="standardStage" className="form-label">Standard Stage</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="standardStage"
                                    value={standardStage}
                                    onChange={(e) => setStandardStage(e.target.value)}
                                    placeholder="Enter the standard stage"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="graphicPattern" className="form-label">Graphic Pattern</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="graphicPattern"
                                    value={graphicPattern}
                                    onChange={(e) => setGraphicPattern(e.target.value)}
                                    placeholder="Enter the graphic pattern"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="trend" className="form-label">Trend</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="standardStage"
                                    value={trend}
                                    onChange={(e) => setTrend(e.target.value)}
                                    placeholder="Describe the trend"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="tradeSetup" className="form-label">Trade Setup</label>
                                <textarea
                                    className="form-control"
                                    id="tradeSetup"
                                    value={tradeSetup}
                                    onChange={(e) => setTradeSetup(e.target.value)}
                                    placeholder="Enter the trade setup details"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="graphicPattern_description" className="form-label">Graphic Pattern Description</label>
                                <textarea
                                    className="form-control"
                                    id="graphicPattern_description"
                                    value={graphicPatternDescription}
                                    onChange={(e) => setGraphicPattern_Description(e.target.value)}
                                    placeholder="Describe the graphic pattern"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="momentum" className="form-label">Momentum</label>
                                <textarea
                                    className="form-control"
                                    id="momentum"
                                    value={momentum}
                                    onChange={(e) => setMomentum(e.target.value)}
                                    placeholder="Describe the momentum"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="supportAndResistance" className="form-label">Support and Resistance</label>
                                <textarea
                                    className="form-control"
                                    id="supportAndResistance"
                                    value={supportAndResistance}
                                    onChange={(e) => setSupportAndResistance(e.target.value)}
                                    placeholder="Describe the support and resistance"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="s3Url" className="form-label">Graphic File</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="s3Url"
                                    onChange={(e) => setGraphicFile(e.target.files[0])}
                                    accept="image/*"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="card card-body border-0 shadow table-wrapper table-responsive">
                        <TableContainer component={Paper}>
                            <Table className="table table-hover" aria-label="collapsible table">
                                <TableHead>
                                    <TableRow className="d-block mb-4 mb-md-0">
                                        <TableCell className="h4">Graphic Analysis</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="border-gray-200">
                                    {(images || []).map((image) => (
                                        <TableRow key={image.id}>
                                            <TableCell>
                                                <HelpRow row={image} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <button
                                                    className="btn btn-danger"
                                                    disabled={deleting}
                                                    onClick={() => handleDelete(image.id)}
                                                >
                                                    {deleting ? 'Deleting...' : 'Delete'}
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <br></br>
                            <Pagination
                                count={Math.ceil(count / 8)} // Número total de páginas
                                page={page}
                                onChange={handlePageChange} // Lida com a mudança de página
                            />
                            <br></br>
                        </TableContainer>

                    </div>
                </div>

                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
        </>
    );
}
