import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/**
 * props:
 * - data
 * - onClick
 */
function HelpRow(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    // Remover "USDT" do final de row.symbol, se existir
    const processedSymbol = row.symbol.replace(/USDT$/i, '').toLowerCase();

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <div className="flex items-center space-x-4">
                        <div>
                            <h3 className="font-semibold text-lg text-gray-600">
                                <img
                                    alt={row.symbol}
                                    className="me-2"
                                    width={30}
                                    src={
                                        processedSymbol.startsWith("ld")
                                            ? `/img/icons/color/${processedSymbol.slice(2)}.svg`
                                            : `/img/icons/color/${processedSymbol}.svg`
                                    }
                                />
                                {row.title}</h3>
                        </div>
                    </div>
                </TableCell>
                <TableCell />
                <TableCell align="right">{row.imageId}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="image details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><h1>Graphical analyst of {row.title}</h1></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Box >
                                                {/* Exibição da Imagem */}
                                                <a href={row.s3Url} target="_blank" rel="noopener noreferrer" >
                                                    <img
                                                        src={row.s3Url}
                                                        alt={row.title}
                                                    />
                                                </a>

                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography color="textSecondary" sx={{ whiteSpace: 'pre-line' }}>
                                                        <div className="flex flex-col space-y-2 items-end">
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Feeling</h4>
                                                                <span className="text-lg font-medium text-blue-400">{row.feeling}</span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Trend</h4>
                                                                <span className="text-lg font-medium text-blue-400">{row.trend}</span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Standard Stage</h4>
                                                                <span className="text-lg font-medium text-blue-400">{row.standardStage}</span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Graphic Pattern</h4>
                                                                <span className="text-lg font-medium text-blue-400">{row.graphicPattern}</span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Graphic Pattern Description</h4>
                                                                <span className="text-lg font-medium text-blue-400">{row.graphicPattern_description}</span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Graphic Pattern</h4>
                                                                <span className="text-lg font-medium text-blue-400">{row.graphicPattern}</span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Trade Setup</h4>
                                                                <span className="text-lg font-medium text-blue-400">{row.tradeSetup}</span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Momentum</h4>
                                                                <span className="text-lg font-medium text-blue-400">{row.momentum}</span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Support and Resistance</h4>
                                                                <span className="text-lg font-medium text-blue-400">{row.support_and_Resistance}</span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Date</h4>
                                                                <span className="text-lg font-medium text-blue-400">
                                                                    {new Intl.DateTimeFormat('en-US', {
                                                                        year: 'numeric',
                                                                        month: 'long',
                                                                        day: '2-digit',
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        second: '2-digit',
                                                                    }).format(new Date(row.updatedAt))}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <h4 className="font-semibold text-lg text-gray-600">Attention</h4>
                                                                <span className="text-lg font-medium text-blue-400">
                                                                    Investing involves risk and this content does not constitute a purchase recommendation. We encourage you to conduct your own research and make investment decisions aligned with your objectives and risk profile.</span>
                                                            </div>
                                                        </div>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default HelpRow;
